<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid" /> {{ caption }}
        <div class="card-header-actions">
          <CButton size="sm" color="secondary" class="" @click="refresh">
            Refresh
          </CButton>
          <CButton
            v-if="$isAccountOwner(accountId) && createClbk != undefined"
            size="sm"
            color="info"
            class=""
            @click="create"
          >
            Create new Permission Set
          </CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <div v-if="!loading">
        <CDataTable
          :hover="hover"
          :striped="striped"
          :border="border"
          :small="small"
          :fixed="fixed"
          :items="itemsExt"
          :fields="fields"
          items-per-page-select
          :items-per-page="small ? 20 : 10"
          :dark="dark"
          pagination
          sorter
          :sorterValue="{ column: 'RoleName', asc: true }"
          tableFilter
          clickableRows
          v-on:row-clicked="clicked"
        >
          <template #RoleName="{ item }">
            <td>
              {{ item.RoleName }}
              <CBadge
                v-if="editable && $isCCoEBaseline(item.RoleName)"
                color="secondary"
                >OneCloud managed</CBadge
              >
            </td>
          </template>
          <template #RoleStatus="{ item }">
            <td>
              <CBadge :color="getBadge(item.RoleStatus)">{{
                item.RoleStatus
              }}</CBadge>
            </td>
          </template>
          <template #show_details="{ item, index }">
            <td class="py-2">
              <CButton
                v-if="(item.RoleStatus === 'PROVISIONNED')"
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="toggleDetails(item, index)"
              >
                {{ Boolean(item._toggled) ? "Hide" : "Show" }}
              </CButton>
            </td>
          </template>
          <template #details="{ item }">
            <CCollapse
              :show="Boolean(item._toggled)"
              :duration="collapseDuration"
            >
              <CCardBody>
                <!-- <CMedia :aside-image-props="{ height: 102 }"> -->
                <h4>
                  {{item.RoleName}}
                  <CButton
                    v-if="
                      editable &&
                      !$isCCoEBaseline(item.RoleName) &&
                      $isAccountOwner(accountId)
                    "
                    size="sm"
                    color="info"
                    class="ml-1"
                    @click="editItem(item)"
                  >
                    Edit
                  </CButton>
                  <CButton
                    v-else
                    size="sm"
                    color="info"
                    class="ml-1"
                    @click="editItem(item, $isCCoEBaseline(item.RoleName))"
                  >
                    View
                  </CButton>
                  <CButton
                    size="sm"
                    color="danger"
                    class="ml-1"
                    v-if="
                      deletable &&
                      !$isCCoEBaseline(item.RoleName) &&
                      $isAccountOwner(accountId)
                    "
                    @click="deleteItemWarn(item)"
                    :disabled="$isCCoEBaseline(item.RoleName)"
                  >
                    Delete
                  </CButton>
                </h4>
                <p>
                  Description:
                  {{
                    item.RoleDescription == undefined
                      ? "N/A"
                      : item.RoleDescription
                  }}
                </p>

                <CTextarea
                  placeholder="PAA (GID-A) separated by a comma"
                  size="sm"
                  v-model="multiUsersAssign.username"
                  :is-valid="multiUsersValidator"
                />
                <CButton
                  v-if="$isAccountOwner(accountId)"
                  size="sm"
                  color="info"
                  class=""
                  @click="assignItem(item)"
                  :disabled="!multiUsersAssign.isValid"
                >
                  Assign user
                </CButton>
                <!-- </CMedia> -->
              </CCardBody>
            </CCollapse>
          </template>
        </CDataTable>
      </div>
      <div v-else align="center">
        <img src="/img/tenor.gif" width="50" />
      </div>
    </CCardBody>
    <div>
      <CModal
        title="Delete permission set"
        color="danger"
        :show.sync="dangerModal.show"
      >
        Are you sure you want to delete the permission set
        {{ dangerModal.RoleName }} ?<br />
        This action is not reversible and all assignment will be lost.<br /><br />
        Type 'CONFIRM' in the following input box and click on DELETE button to
        confirm<br />
        <CInput
          label="Confirm"
          placeholder=""
          class="mb-0 mx-1"
          size="sm"
          horizontal
          v-model="dangerModal.confirm"
        />
        <template #footer>
          <CButton
            @click="
              dangerModal.show = false;
              dangerModal.confirm = '';
            "
            color="secondary"
            >Cancel</CButton
          >
          <CButton @click="deleteItem(dangerModal.RoleName)" color="danger"
            >DELETE</CButton
          >
        </template>
      </CModal>
    </div>
  </CCard>
</template>

<script>
export default {
  name: "TableRoleList",
  props: {
    accountId: String,
    items: Array,
    fields: {
      type: Array,
      default() {
        return ["RoleName", "RoleStatus", "show_details"];
      }
    },
    caption: {
      type: String,
      default: "Table"
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    deletable: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    assignClbk: {
      type: Function,
      default: undefined
    },
    deleteClbk: {
      type: Function,
      default: undefined
    },
    editClbk: {
      type: Function,
      default: undefined
    },
    createClbk: {
      type: Function,
      default: undefined
    },
    refreshClbk: {
      type: Function,
      default: undefined
    }
  },
  computed: {
    itemsExt: function() {
      if (this.items && this.items != undefined) {
        var res = this.items.map((item, id) => {
          if (item.hasOwnProperty("RoleStatus")) return { ...item, id };
          else return { ...item, RoleStatus: "PROVISIONNED", id };
        });
        return res;
      } else {
        return [];
      }
    }
  },
  watch: {
    items: function(newItem) {
      if (newItem != undefined) this.loading = false;
    }
  },
  data() {
    return {
      collapseDuration: 0,
      dangerModal: {
        show: false,
        RoleName: undefined,
        confirm: ""
      },
      multiUsersAssign: {
        multiUsers: "",
        isValid: false
      },
      selectedItem: -1,
      loading: this.items == undefined
    };
  },
  methods: {
    getBadge(status) {
      return status === "PROVISIONNED" ? "success" : "secondary";
    },
    clicked(event) {
      if (this.selectedItem != event.id) {
        this.selectedItem = event.id;
        this.$emit("row-clicked", event);
      }
    },
    toggleDetails(item) {
      this.$set(this.itemsExt[item.id], "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    async editItem(item) {
      console.log("edit");
      if (this.editClbk != undefined) {
        await this.editClbk(item);
      }
    },
    deleteItemWarn(item) {
      this.dangerModal.show = true;
      this.dangerModal.RoleName = item.RoleName;
    },
    async deleteItem(item) {
      this.dangerModal.show = false;
      if (
        this.deleteClbk != undefined &&
        this.dangerModal.confirm === "CONFIRM"
      ) {
        await this.deleteClbk(item);
      }
      this.dangerModal.confirm = "";
    },
    async assignItem(item) {
      for (let user of this.multiUsersAssign.username.split(",")) {
        console.log(
          "Assign " +
            JSON.stringify({
              role: item,
              user: user.toLowerCase() + "@engie.com"
            })
        );
        if (this.assignClbk != undefined) {
          await this.assignClbk({
            role: item,
            user: user.toLowerCase() + "@engie.com"
          });
        }
      }
    },
    async create() {
      console.log("Create ");
      if (this.createClbk != undefined) {
        await this.createClbk();
      }
    },
    async refresh() {
      console.log("Refresh ");
      this.loading = true;
      if (this.refreshClbk != undefined) {
        await this.refreshClbk();
      }
      this.loading = false;
    },
    multiUsersValidator(val) {
      let isValid = true;
      if (val === "") return false;
      if (val === undefined) isValid = false;
      else {
        var nameArr = val.split(",");
        for (let k of nameArr) {
          const regex = /^([A-Z]|[a-z]|[0-9]){6}-([A-Z]|[a-z])$/g;
          const found = k.match(regex);
          this.multiUsersAssign.isValid = !(found == null);
          isValid = isValid && !(found == null);
        }
      }
      return isValid;
    }
  }
};
</script>
